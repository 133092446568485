.brand-name {
    font-family: Monaco, sans-serif; // Specify the font family
    font-size: 1em; // Specify the font size
    font-style: normal; // Reset the font style
    color: $gray-200; // Using the color variable
    display: inline-block;
  
    i {
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      font-size: 1em;
      color: $teal;
      font-style: italic; // You can keep or remove this line since <i> is italic by default
    }
  
    b {
      font-weight: bold; // You can keep or remove this line since <b> is bold by default
      color: $red;
      font-size: 1em;
      font-style: italic;
    }
  }
  